export type ModalType<T = void> = {
  isOpen: boolean;
  onClose(): void;
  additionalProps: T;
};

export enum Toast {
  NONE,
  FORM_UPDATE_SUCCESS,
  FORM_UPDATE_ERROR_UNKNOWN,
  FORM_UPDATE_ERROR_INTERNAL,
  FORM_UPDATE_ERROR_UNAUTHENTICATED,
  FORM_UPDATE_ERROR_UNAVAILABLE,
  FORM_UPDATE_ERROR_PERMISSION_DENIED,
  FORM_UPDATE_ERROR_INVALID_ARGUMENT,
  FORM_UPDATE_ERROR_INVALID_URL,
  PROFILE_URL_UPDATE_SUCCESS,
  PROFILE_URL_UPDATE_ERROR,
  PROFILE_PRIVACY_CHANGE_SUCCESS,
  PROFILE_PRIVACY_CHANGE_ERROR,
  UNBLOCK_MEMBER_SUCCESS,
  UNBLOCK_MEMBER_ERROR,
  LOGIN_INFO_TECHNICAL_ERROR,
  LOGIN_INFO_EMAIL_CHANGE_SUCCESS,
  LOGIN_INFO_PASSWORD_CHANGE_SUCCESS,
}

type UnblockedToastConfig = {
  type: Toast.UNBLOCK_MEMBER_SUCCESS;
  options: { memberName: string };
};

type GenericToastConfig = {
  type:
    | Toast.NONE
    | Toast.FORM_UPDATE_SUCCESS
    | Toast.FORM_UPDATE_ERROR_UNKNOWN
    | Toast.FORM_UPDATE_ERROR_INTERNAL
    | Toast.FORM_UPDATE_ERROR_UNAUTHENTICATED
    | Toast.FORM_UPDATE_ERROR_UNAVAILABLE
    | Toast.FORM_UPDATE_ERROR_PERMISSION_DENIED
    | Toast.FORM_UPDATE_ERROR_INVALID_ARGUMENT
    | Toast.FORM_UPDATE_ERROR_INVALID_URL
    | Toast.PROFILE_URL_UPDATE_SUCCESS
    | Toast.PROFILE_URL_UPDATE_ERROR
    | Toast.PROFILE_PRIVACY_CHANGE_SUCCESS
    | Toast.PROFILE_PRIVACY_CHANGE_ERROR
    | Toast.UNBLOCK_MEMBER_ERROR
    | Toast.LOGIN_INFO_TECHNICAL_ERROR
    | Toast.LOGIN_INFO_EMAIL_CHANGE_SUCCESS
    | Toast.LOGIN_INFO_PASSWORD_CHANGE_SUCCESS;
};

export type ToastConfig = UnblockedToastConfig | GenericToastConfig;

export type ToastState = {
  isVisible: boolean;
} & ToastConfig;
